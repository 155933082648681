<script setup>
import { useStore } from 'vuex';
import { getModuleData } from '@utils/modules';
import { computed, onBeforeMount, ref } from 'vue';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';
import { useInstitutionStore } from '@stores/institution';

const store = useStore();
const institutionStore = useInstitutionStore();

const isLoading = ref(true);

const moduleData = computed(() => store.getters['meals/all']);

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().MEAL_PLAN;

onBeforeMount(async () => {
  if (institutionStore.settings.useExternalMeals) {
    await store.dispatch('meals/getPcdMeals', { useCache: true });
  } else {
    await store.dispatch('meals/getMeals', { useCache: true });
  }

  isLoading.value = false;
});
</script>

<template>
  <ModuleBaseView
    v-if="!isLoading"
    :moduleName="emptyKey"
    :moduleData="moduleData"
    :colorHex="colorHex"
    :icon="icon"
    :emptyKey="emptyKey"
    :routeName="routeName"
    :entryRouterName="entryRouterName"
  />
</template>
