<script setup>
import { computed } from 'vue';
import ModuleNavButton from './ModuleNavButton.vue';
import i18n from '@i18n';
import { useRouter, useRoute } from 'vue-router';
import colors from '@utils/colors.js';
import { useInstitutionStore } from '@stores/institution';
import { useGeneralStore } from '@stores/general';
import { useDepartmentStore } from '@stores/department';

const emit = defineEmits(['close-overlay']);
const route = useRoute();
const router = useRouter();
const generalStore = useGeneralStore();
const institutionStore = useInstitutionStore();
const departmentStore = useDepartmentStore();

const modules = computed(() => {
  const footerConfig = departmentStore.settings.screenConfig.footerConfig ?? [];

  const modules = router.options.routes.filter(
    (route) => route.path !== '/' && route?.meta?.hideInModuleList !== true
  );

  const filteredModules = modules.filter((module) => {
    if (
      module.meta.isExternal &&
      !institutionStore.externalConnections.length
    ) {
      return false;
    }
    return footerConfig.includes(module.name);
  });

  const orderedModules = filteredModules.sort((a, b) => {
    const A = a['name'];
    const B = b['name'];
    return footerConfig.indexOf(A) > footerConfig.indexOf(B) ? 1 : -1;
  });
  return orderedModules;
});

const activeRoute = computed(
  () =>
    modules.value.filter((module) => route.path.indexOf(module.path) > -1)[0]
      ?.path
);

const getModuleData = (index) => {
  if (!modules?.value || !modules.value[index]) return null;

  const module = modules.value[index];
  const meta = module.meta;
  const icon = meta?.isExternal
    ? institutionStore.externalConnections[meta.connectionIndex]?.icon
    : ['fas', `${meta?.icon}`];

  return {
    color: colors[module.name],
    isExternal: meta?.isExternal,
    icon,
    src: `/img/external/${icon}.png`,
    name: meta?.isExternal
      ? institutionStore.externalConnections[meta?.connectionIndex]?.name
      : i18n.global.t(`moduleNames.${module?.name}`),
  };
};

const modulesGap = computed(() => {
  if (modules.value.length >= 9) return '3';
  return '6';
});

const moduleWidth = computed(() => {
  if (modules.value.length <= 4) return 140;
  return 104;
});

function routerLinkClicked() {
  generalStore.setActiveOverlay({});
  emit('close-overlay');
}
</script>

<template>
  <div class="flex justify-center h-[116px]" :class="[`gap-${modulesGap}`]">
    <ModuleNavButton
      v-for="(module, index) in modules"
      :key="`module-nav-button-${index}`"
      :route="module.path"
      :backgroundColor="getModuleData(index).color"
      :isFocused="activeRoute === module.path"
      :isExternal="getModuleData(index).isExternal"
      :icon="getModuleData(index).icon"
      :title="getModuleData(index).name"
      @click.native="routerLinkClicked"
      :pixelWidth="moduleWidth"
    />
  </div>
</template>
