<script setup>
import { onBeforeMount, computed } from 'vue';
import VueSlider from 'vue-3-slider-component';
import colors from '@utils/colors';
import { useGeneralStore } from '@stores/general';

const generalStore = useGeneralStore();

const dimmer = computed({
  get: () => generalStore.dimmer,
  set: (value) => {
    generalStore.setDimmer(value);
  },
});

onBeforeMount(async () => {
  // Store has not been initiated with application storage value
  if (dimmer.value === -1) {
    // Check application storage value
    const storageValue = localStorage.getItem('dimmer');

    // If the value is less than 0, set to 100 to prevent blacking out the screen.
    if (storageValue < 1) {
      generalStore.setDimmer(100);
    } else {
      generalStore.setDimmer(storageValue);
    }
  }
});
</script>

<template>
  <VueSlider
    direction="btt"
    :contained="true"
    v-model="dimmer"
    :processStyle="{
      backgroundColor: colors.darkOcean[500],
    }"
    :railStyle="{
      backgroundColor: colors.neutral[400],
    }"
    width="10px"
    tooltip="none"
  >
    <template #dot>
      <div
        class="flex flex-col justify-center items-center h-[52px] w-[26px] bg-neutral-0 rounded-full relative left-1/2 transform -translate-x-1/2 -translate-y-1/2 border border-neutral-300"
        style="top: 50%"
      >
        <fa-icon class="block" :icon="['fas', 'fa-chevron-up']" />
        <fa-icon class="block" :icon="['fas', 'fa-chevron-down']" />
      </div>
    </template>
  </VueSlider>
</template>
