<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { get } from 'lodash';
import { useRoute, useRouter } from 'vue-router';

import CoverView from '@views/shared/CoverView.vue';
import DescriptionBlock from '@components/shared/blocks/DescriptionBlock.vue';
import MediaIcons from '@components/shared/MediaIcons.vue';
import MediaList from '@views/shared/MediaList.vue';
import MediaPreview from '@views/overlays/MediaPreview.vue';
import ProfilesList from '@components/shared/ProfilesList.vue';
import { useInstitutionStore } from '@stores/institution';
import { useDepartmentStore } from '@stores/department';
import { useGeneralStore } from '@stores/general';

const store = useStore();
const institutionStore = useInstitutionStore();
const departmentStore = useDepartmentStore();
const generalStore = useGeneralStore();
const route = useRoute();
const router = useRouter();

const showSelectDepartment = ref(false);
const showMediaList = ref(false);
const mediaType = ref('');
const isLoading = ref(true);
const selectedMediaIndex = ref(-1);
const hideMediaPreview = ref(false);
const selectedDepartmentId = ref(-1);

const myArchive = computed(() => store.getters['profiles/myArchive']);
const selectedProfile = computed(() => store.getters['profiles/activeProfile']);

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const selectedDepartment = computed(() => {
  return institutionStore.departments.find(
    (department) =>
      parseInt(department.id) === parseInt(selectedDepartmentId.value)
  );
});

const coverImage = computed(() => {
  return get(
    selectedDepartment.value,
    'presentation.image',
    '/assets/img/placeholder.png'
  );
});

const description = computed(() => {
  return get(selectedDepartment.value, 'presentation.description', '');
});

const documents = computed(() => {
  return myArchive?.value?.documents || [];
});

const mediaIcons = computed(() => {
  return [
    {
      name: 'documents',
      fa: 'file-alt',
      amount: documents.value.length || 0,
    },
  ];
});

const showMediaPreview = computed(() => {
  return selectedMediaIndex.value > -1 && !hideMediaPreview.value;
});

onMounted(async () => {
  await generalStore.setActiveContentView({});

  switchDepartment({
    id: departmentStore.id,
    type: 'DEPARTMENT',
  });
  isLoading.value = false;
});

function closeMediaList() {
  mediaType.value = '';
  selectedMediaIndex.value = -1;
  showMediaList.value = false;
}

async function switchDepartment(department) {
  const departmentId = parseInt(department.id);
  selectedDepartmentId.value = departmentId;
  await store.dispatch('profiles/getMyArchive', {
    id: departmentId,
    type: 'DEPARTMENT',
  });
  showSelectDepartment.value = false;
}

async function showSingleProfile(profile) {
  await store.dispatch('profiles/setActiveProfile', profile);
  router.push(`/ourHome/${profile.id}`);
}

function mediaIconSelected() {
  if (documents.value.length === 1) {
    // if only one item, go straight to media-preview
    selectedMediaIndex.value = 0;
    hideMediaPreview.value = false;
    return;
  }

  mediaType.value = 'documents';
  showMediaList.value = true;
}
</script>

<template>
  <div>
    <MediaPreview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :mediaType="mediaType"
      :items="documents"
      :allow-archive-remove="!!selectedProfile.id || false"
      @close-preview="selectedMediaIndex = -1"
    />

    <CoverView
      v-if="!isLoading"
      :gradient="true"
      :image-url="coverImage"
      :module-color="moduleColor"
    >
      <div>
        <MediaIcons
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="documents.length"
          @media-selected="mediaIconSelected($event)"
        />

        <div v-if="!showMediaList" class="flex flex-col mb-12">
          <div class="mx-20">
            <div class="largeListItemHeader pt-20 mb-10">
              {{ selectedDepartment.name }}
            </div>

            <DescriptionBlock :description="description" />
          </div>

          <ProfilesList
            class="mb-12"
            :canClose="false"
            :selected-profile-type="selectedProfile.type"
            :shared-departments="institutionStore.departments"
            :selected-department="selectedDepartment"
            @profile-selected="showSingleProfile($event)"
          />
        </div>

        <div v-else class="p-20">
          <MediaList
            :items="documents"
            :mediaType="mediaType"
            class="pt-20"
            :canClose="true"
            @close="closeMediaList"
            @item-selected="selectedMediaIndex = $event"
          />
        </div>
      </div>
    </CoverView>
  </div>
</template>

<style lang="scss">
.profile {
  .profileImage {
    &.small {
      width: 96px;
      height: 96px;
    }

    &.large {
      width: 180px;
      height: 180px;
    }
  }
}
</style>
