import { apolloClient } from '@utils/apollo';
import store from '@store';
import constants from '@utils/constants';
import { addMonths } from 'date-fns';
import { format } from '@utils/date-fns';
import { meetingRangeQuery } from '@graphql/queries/meetingRange.js';
import { meetingEntryQuery } from '@graphql/queries/meetingEntry.js';
import { meetingSignupScreenMutation } from '@graphql/mutations/meetingSignupScreen.js';
import { meetingCancelSignupScreenMutation } from '@graphql/mutations/meetingCancelSignupScreen.js';
import { meetingSuggestMutation } from '@graphql/mutations/meetingSuggest.js';
import { useDepartmentStore } from '@stores/department';

const state = {
  all: [],
  selected: {},
  suggestion: '',
};

const mutations = {
  SET_DEFAULT_MEETINGS(state, meetings) {
    state.all = meetings;
  },
  SET_SELECTED_MEETING(state, meeting) {
    state.selected = meeting;
  },
  SET_SUGGESTION(state, suggestion) {
    state.suggestion = suggestion;
  },
};

const actions = {
  async getMeetings({ commit }) {
    const departmentStore = useDepartmentStore();

    if (!departmentStore.id) {
      throw new Error('No department id provided');
    }

    const today = new Date();

    await apolloClient
      .query({
        query: meetingRangeQuery,
        variables: {
          filter: {
            departments: departmentStore.id,
          },
          dateFrom: format(new Date(), 'yyyy-MM-dd'),
          dateTo: format(addMonths(today, 12), 'yyyy-MM-dd'),
          timezone: constants.timezone,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        commit('SET_DEFAULT_MEETINGS', response.data.meetingRange);
      })
      .catch((error) => {
        console.log('Error getting meetings', error);
      });
  },
  async getSelectedMeeting({ commit }, meetingId) {
    commit('SET_SELECTED_MEETING', {});
    await apolloClient
      .query({
        query: meetingEntryQuery,
        variables: {
          id: meetingId,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        const meetingEntry = response.data.meetingEntry;

        const updatedResponse = {
          data: {
            meetingEntry: {
              ...meetingEntry,
              participants: meetingEntry.participants.map((participant) => {
                if (!participant.showImage)
                  participant.displayImage = constants.avatarPlaceholder;
                return participant;
              }),
              suggestions: meetingEntry.suggestions.map((suggestion) => {
                if (!suggestion.suggester.showImage)
                  suggestion.suggester.displayImage =
                    constants.avatarPlaceholder;
                return suggestion;
              }),
            },
          },
        };

        commit('SET_SELECTED_MEETING', updatedResponse.data.meetingEntry);
      })
      .catch((error) => {
        console.log('Could not get meeting entry: ', error);
      });
  },
  async meetingSignupScreen({ commit }, { subscription, profile, opt }) {
    const mutation =
      opt === 'in'
        ? meetingSignupScreenMutation
        : meetingCancelSignupScreenMutation;

    await apolloClient
      .mutate({
        mutation: mutation,
        variables: {
          subscription,
          profile,
        },
      })
      .then(async () => {
        await store.dispatch('profiles/setPinValidation', {
          validating: false,
          validPin: true,
        });
        store.dispatch('meetings/getSelectedMeeting', subscription.id);
      })
      .catch((error) => {
        store.dispatch('profiles/setPinValidation', {
          validating: false,
          validPin: false,
        });
        throw new Error('Error signing up to meeting', error);
      });
  },
  setSuggestion({ commit }, suggestion) {
    commit('SET_SUGGESTION', suggestion);
  },
  async meetingSuggest({ commit }, { profile, meetingId }) {
    await apolloClient
      .mutate({
        mutation: meetingSuggestMutation,
        variables: {
          profile,
          meetingId,
          suggestion: state.suggestion,
        },
      })
      .then(() => {
        store.dispatch('meetings/getSelectedMeeting', meetingId);
      })
      .catch(() => {
        throw new Error('Error saving suggestion', error);
      });
  },
};

const getters = {
  all: (state) => state.all,
  selected: (state) => state.selected,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
