<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { isFuture } from 'date-fns';
import i18n from '@i18n';
import { default as ButtonEl } from '@components/shared/Button.vue';
import { capitalize } from 'lodash';
import PinFlow from '@utils/pinFlow';
import CoverView from '@views/shared/CoverView.vue';
import { formatRepeat } from '@utils/helpers';
import ParticipantsBlock from '@components/shared/blocks/ParticipantsBlock.vue';
import InstitutionBlock from '@components/shared/blocks/InstitutionBlock.vue';
import DepartmentsBlock from '@components/shared/blocks/DepartmentsBlock.vue';
import TTS from '@components/shared/Tts.vue';
import DateTimeBlock from '@components/shared/blocks/DateTimeBlock.vue';
import TitleBlock from '@components/shared/blocks/TitleBlock.vue';
import { useGeneralStore } from '@stores/general';

const store = useStore();
const generalStore = useGeneralStore();
const route = useRoute();

const opt = ref('');

const meeting = computed(() => {
  return store.getters['meetings/selected'];
});

const activeProfile = computed(() => {
  return store.getters['profiles/activeProfile'];
});

const pinValidation = computed(() => {
  return store.getters['profiles/pinValidation'];
});

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const signupPossible = computed(() => {
  return meeting.value.signupPossible && !meeting.value.cancelled;
});

const optoutPossible = computed(() => {
  return isFuture(new Date(meeting.value.startDate));
});

const category = computed(() => {
  return capitalize(meeting.value.category);
});

const repeatText = computed(() => {
  const frequency = formatRepeat(meeting.value.repeat);
  return frequency ? i18n.global.t(`rrule.${frequency}`) : '';
});

const isAlreadyOptedInOut = computed(() => {
  const participantIds = meeting.value.participants.map(
    (participant) => participant.id
  );

  return participantIds.includes(activeProfile.value.id);
});

watch(pinValidation, (pinData) => {
  if (!pinData.validating) return;

  if (!opt.value) {
    const signupData = {
      profile: {
        profileId: activeProfile.value.id,
        profileType: activeProfile.value.type,
        pin: pinData.pin.toString(),
      },
      meetingId: meeting.value.id,
    };

    store.dispatch('meetings/meetingSuggest', signupData);
    return;
  }

  if (
    (opt.value === 'in' && !isAlreadyOptedInOut.value) ||
    (opt.value === 'out' && isAlreadyOptedInOut.value)
  ) {
    const signupData = {
      subscription: {
        id: meeting.value.id,
      },
      profile: {
        profileId: activeProfile.value.id,
        profileType: activeProfile.value.type,
        pin: pinData.pin.toString(),
      },
      opt: opt.value,
    };

    store.dispatch('meetings/meetingSignupScreen', signupData);
  }
});

onMounted(() => {
  store.dispatch('meetings/getSelectedMeeting', route.params.id);
});

function showProfiles(profileType) {
  generalStore.setActiveOverlay({
    name: 'participants-list',
    data: {
      title: `global.${profileType}`,
      profiles: meeting.value[profileType],
    },
  });
}

function showAgenda() {
  generalStore.setActiveOverlay({
    name: 'agenda',
    data: {
      title: i18n.global.t('meeting.agenda'),
      items: meeting.value.agendaPoints,
    },
  });
}

function showSuggestions() {
  generalStore.setActiveOverlay({
    name: 'suggestions',
    data: {
      title: i18n.global.t('meeting.suggestions'),
      suggestions: meeting.value.suggestions,
      sharedDepartments: meeting.value.departments,
    },
  });
}

async function optInOrOut(payload) {
  opt.value = payload;

  const flow = new PinFlow({
    entity: 'meeting',
    text: 'meeting.theMeeting',
    opt: payload,
    title: meeting.value.title,
    sharedDepartments: meeting.value.departments,
    participants: payload === 'in' ? [] : meeting.value.participants,
  });

  flow.startSignupFlow('meeting');
}
</script>

<template>
  <div class="meeting">
    <CoverView
      v-if="meeting.id"
      :image-url="meeting.coverImage.source"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="meetingContent tts-content s">
        <TTS entity-type="MEETING" />
        <div>
          <TitleBlock margin="pt-14 mb-14" :text="meeting.title"></TitleBlock>

          <div>
            <!-- Category -->
            <div
              v-if="category"
              class="relative content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6"
                :icon="['fal', 'tag']"
                :aria-label="$t('meeting.category')"
              />
              <span :aria-label="category">{{ category }}</span>
            </div>

            <!-- Date -->
            <DateTimeBlock :start="meeting.startDate" :end="meeting.endDate" />

            <!-- Repeat -->
            <div
              v-if="meeting.repeat && repeatText.length"
              class="relative content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6"
                :icon="['fal', 'repeat']"
                :aria-label="$t('global.repeats')"
              />
              <span :aria-label="repeatText">{{ repeatText }}</span>
            </div>

            <!-- Location -->
            <div
              v-if="meeting.location"
              class="content pb-10 flex items-center w-full"
            >
              <fa-icon
                class="mr-6"
                :icon="['fal', 'map-marker-alt']"
                :aria-label="$t('global.location')"
              />
              <span :aria-label="meeting.location">{{ meeting.location }}</span>
            </div>

            <!-- Agenda -->
            <div
              v-if="meeting.agendaPoints.length"
              class="content pb-10 flex items-center w-full"
              @click="showAgenda"
            >
              <fa-icon class="mr-6" :icon="['fal', 'list']" />
              <p
                class="font-semibold flex-1"
                :aria-label="
                  $t('meeting.agendaPoints', {
                    amount: meeting.agendaPoints.length,
                  })
                "
              >
                {{
                  $t('meeting.agendaPoints', {
                    amount: meeting.agendaPoints.length,
                  })
                }}
              </p>
              <fa-icon class="text-5xl" :icon="['fal', 'angle-right']" />
            </div>

            <!-- Suggestions -->
            <div
              class="content pb-10 flex items-center w-full"
              @click="showSuggestions"
            >
              <fa-icon class="mr-8" :icon="['fal', 'lightbulb']" />

              <p
                class="font-semibold flex-1"
                :aria-label="
                  $tc('meeting.suggestionItems', meeting.suggestions.length)
                "
              >
                {{ $tc('meeting.suggestionItems', meeting.suggestions.length) }}
              </p>

              <fa-icon class="text-5xl" :icon="['fal', 'angle-right']" />
            </div>

            <ParticipantsBlock
              v-if="meeting.participants.length"
              text="global.participantsPlural"
              :module-color="moduleColor"
              :profiles="meeting.participants"
              @show-people="showProfiles('participants')"
            />

            <DepartmentsBlock
              v-if="meeting.departments.length"
              :departments="meeting.departments"
            />

            <InstitutionBlock />
          </div>
        </div>
      </div>

      <div class="meetingActions flex w-full sticky justify-end">
        <ButtonEl
          v-if="signupPossible"
          text="global.signUp"
          icon="check"
          background-color="success"
          text-color="white"
          class="mr-5 shadow-xsm"
          @click="optInOrOut('in')"
        />

        <ButtonEl
          v-if="optoutPossible && signupPossible"
          text="global.optOut"
          icon="minus-circle"
          background-color="error"
          text-color="white"
          class="shadow-xsm"
          @click="optInOrOut('out')"
        />
      </div>
    </CoverView>
  </div>
</template>

<style lang="scss">
.meeting {
  .meetingContent {
    padding: 0 100px;

    &.participants {
      margin-top: 700px;
    }
  }

  .meetingActions {
    bottom: 70px;
    padding-right: 80px;
  }
}
</style>
