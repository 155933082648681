<script setup>
import { useInstitutionStore } from '@stores/institution';

const institutionStore = useInstitutionStore();

defineProps({
  title: {
    type: String,
    default: null,
  },
  margin: {
    type: String,
    default: 'mb-14',
  },
});
</script>

<template>
  <div class="relative content flex items-start w-full" :class="margin">
    <fa-icon
      :aria-label="$t('global.institution')"
      class="mr-6"
      :icon="['fal', 'hotel']"
    />
    <span :aria-label="title ?? institutionStore.name" class="font-light">{{
      title ?? institutionStore.name
    }}</span>
  </div>
</template>
