<script setup>
import { ref, computed } from 'vue';
import { VueperSlides, VueperSlide } from 'vueperslides';
import CarouselControls from '@components/shared/CarouselControls.vue';
import ArchiveControls from '@components/shared/ArchiveControls.vue';
import { useSwipe } from '@vueuse/core';
import { useDepartmentStore } from '@stores/department';

const slider = ref();
const swipingDirection = ref();
const sliderReady = ref(false);
const autoPlaying = ref(false);
const currentSlide = ref();
const departmentStore = useDepartmentStore();

const emit = defineEmits(['archive-add', 'archive-remove', 'close-preview']);

// We need to override the touch in Vueperslides as our screens don't respond to normal touch / click events
const { direction, lengthX } = useSwipe(slider, {
  passive: true,
  onSwipe() {
    swipingDirection.value = direction.value;
  },
  onSwipeEnd() {
    if (swipingDirection.value === 'left' && lengthX.value >= 50) {
      slider.value.next();
    } else if (lengthX.value <= -50) {
      slider.value.previous();
    }
  },
});

const props = defineProps({
  pictures: {
    type: Array,
    default: () => [],
  },
  selectedItemIndex: {
    type: Number,
    default: 0,
  },
  allowArchiveRemove: {
    type: Boolean,
    default: false,
  },
});

const duration = computed(() => {
  return departmentStore.settings.screenConfig.carouselDuration * 1000;
});

function onSliderReady() {
  sliderReady.value = true;
  slider.value.goToSlide(props.selectedItemIndex);
}

function goNext() {
  slider.value.next();
}

function goPrevious() {
  slider.value.previous();
}

function onSlide(event) {
  currentSlide.value = event.currentSlide.index;
}

function toggleAutoplay() {
  autoPlaying.value
    ? slider.value.pauseAutoplay()
    : slider.value.resumeAutoplay();
  autoPlaying.value = !autoPlaying.value;
}

function archiveAddRemove(addOrRemove) {
  emit(`archive-${addOrRemove}`, {
    mediaType: 'pictures',
    id: props.pictures[currentSlide.value].id,
  });
}
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <VueperSlides
      class="w-full mb-16 no-shadow"
      ref="slider"
      fixed-height="1000px"
      :start-index="selectedItemIndex"
      :arrows="false"
      :bullets="false"
      :touchable="true"
      :draggingDistance="20000"
      :style="{ width: '950px' }"
      :autoplay="autoPlaying"
      :duration="duration"
      :infinite="pictures.length > 1"
      @ready="onSliderReady()"
      @slide="onSlide($event)"
    >
      <VueperSlide
        v-for="(picture, index) in pictures"
        :key="index"
        class="w-full h-full"
      >
        <template #content>
          <div class="w-full h-full flex items-center justify-center">
            <img
              :src="picture.source"
              :style="{
                maxHeight: '1200px',
                maxWidth: '950px',
                height: 'auto',
                width: 'auto',
              }"
            />
          </div>
        </template>
      </VueperSlide>
    </VueperSlides>
  </div>

  <ArchiveControls
    v-if="sliderReady"
    :allowArchiveRemove="allowArchiveRemove"
    mediaType="pictures"
    @archive-add="archiveAddRemove('add')"
    @archive-remove="archiveAddRemove('remove')"
  ></ArchiveControls>

  <CarouselControls
    v-if="sliderReady"
    :total-slides="pictures.length"
    :current-slide="$refs.slider.slides.current + 1"
    media-type="pictures"
    :autoplay="autoPlaying"
    @autoplay-toggled="toggleAutoplay()"
    @previous="goPrevious()"
    @next="goNext()"
    @close="$emit('close-preview')"
  />
</template>
