import { defineStore } from 'pinia';
import overlays from '@utils/overlays';

export const useGeneralStore = defineStore('general', {
  state: () => ({
    overlays,
    isLoading: true,
    isSaving: false,
    activeOverlay: {},
    nextActiveOverlay: {},
    activePinFlow: '',
    activeContentView: null,
    activeViewType: null,
    dimmer: -1,
    currentDate: {},
    idleCount: 0,
  }),
  actions: {
    setLoading(loading) {
      this.isLoading = loading;
    },
    setSaving(saving) {
      this.isSaving = saving;
    },
    setActiveOverlay(activeOverlay) {
      const overlay = this.overlays.find(
        (overlay) => overlay.name === activeOverlay.name
      );
      if (activeOverlay.data) overlay.data = activeOverlay.data;
      this.activeOverlay = overlay || {};

      if (activeOverlay.name) {
        window._paq.push(['trackEvent', 'OverlayViewed', activeOverlay.name]);
      }
    },
    setNextActiveOverlay(nextActiveOverlay) {
      this.nextActiveOverlay = nextActiveOverlay;
    },
    resetOverlays() {
      this.activeOverlay = {};
      this.nextActiveOverlay = {};
    },
    setActivePinFlow(flow) {
      this.activePinFlow = flow;
    },
    setActiveContentView(activeContentView) {
      this.activeContentView = activeContentView;
    },
    setActiveViewType(activeViewType) {
      this.activeViewType = activeViewType;
      window._paq.push(['trackEvent', 'ViewType', activeViewType]);
    },
    setDimmer(dimmer) {
      if (!dimmer) {
        // If localStorage value is empty, parseInt returns -1
        dimmer = parseInt(localStorage.getItem('dimmer')) || 100;
      }

      localStorage.setItem('dimmer', dimmer);
      this.dimmer = dimmer;
    },
    setCurrentDate(date) {
      this.currentDate = date;
    },
    setIdle() {
      this.idleCount += 1;
    },
  },
});
