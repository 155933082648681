import { createApp } from 'vue';
import App from './App.vue';

import '@utils/icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import Vue3TouchEvents from 'vue3-touch-events';
import VueMatomo from 'vue-matomo';

import router from '@router';
import store from '@store';
import i18n from '@i18n';
import clickoutside from '@directives/clickoutside';
import * as constants from '@utils/constants.js';
import 'animate.css';
import '@scss/main.scss';
import { createManager } from '@vue-youtube/core';
import vueVimeoPlayer from 'vue-vimeo-player';
import lazyload from '@directives/lazyload';
import { sentryInit } from '@utils/sentryConfig.js';
import { createPinia } from 'pinia';

// Needed to detect if the JS was fetched correctly. Check index.html to see where it's being used.
const body = document.getElementsByTagName('body');
delete body[0].dataset.jsnotloaded;

const ytManager = createManager({
  deferLoading: {
    enabled: true,
    autoLoad: true,
  },
});
const pinia = createPinia();
const app = createApp(App);

app.directive('clickoutside', clickoutside);

app.config.productionTip = false;
app.config.devtools = import.meta.env.NODE_ENV === 'development';

sentryInit(app);

app.directive('lazy', lazyload);

app.config.globalProperties.$constants = constants.default;

app
  .use(i18n)
  .use(router)
  .use(store)
  .use(Vue3TouchEvents)
  .use(ytManager)
  .use(vueVimeoPlayer)
  .use(pinia)
  .use(VueMatomo, {
    host: 'https://aws.proreact.dk/tracking/',
    siteId: 10,
    router,
  })
  .component('faIcon', FontAwesomeIcon);

app.mount('#app');
