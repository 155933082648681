<script setup>
import { onBeforeMount, onMounted, onUpdated } from 'vue';
import { getModuleData } from '@utils/modules';
import { useRouter } from 'vue-router';
import ModuleIconTitle from '@components/ModuleIconTitle.vue';
import ModuleEmptyCard from '@components/cards/moduleEmptyCard/ModuleEmptyCard.vue';
import CardBasic from '@components/cards/Basic.vue';
import { useBookingResourcesStore } from '@stores/bookingResources';
import { useCacheStore } from '@stores/cache';

const bookingResourcesStore = useBookingResourcesStore();
const cache = useCacheStore();
const router = useRouter();

onBeforeMount(() => {
  cache.use(
    'bookingResourcesStore.getBookingResources',
    bookingResourcesStore.getBookingResources
  );
});

onMounted(() => {
  const content = document.getElementById('content');
  if (content) {
    content.scrollIntoView(true, { behavior: 'instant', block: 'start' });
  }
});

onUpdated(() => {
  const content = document.getElementById('content');
  if (content) {
    content.scrollIntoView(true, { behavior: 'instant', block: 'start' });
  }
});

const resourceSelected = (resource) => {
  bookingResourcesStore.setSelectedBookingResource(resource);
  router.push(`/bookings/resource/${resource.id}`);
};

const { colorHex, icon, emptyKey, entryRouterName } =
  getModuleData().BOOKING_RESOURCES;
</script>

<template>
  <ModuleEmptyCard
    v-if="!bookingResourcesStore.all.length"
    class="flex h-full"
    :emptyKey="emptyKey"
    :backgroundColor="colorHex"
    iconType="fas"
    :icon="icon"
    :entryRouterName="entryRouterName"
  />

  <div v-else>
    <div class="h-[920px] bg-white" id="top-spacing" />

    <div
      id="content"
      class="flex flex-col z-10 contain-content min-h-fit bg-white items-center pb-10"
    >
      <ModuleIconTitle
        moduleName="BOOKING"
        :backgroundColor="colorHex"
        :icon="icon"
        class="pt-2"
      />

      <div class="min-h-[1320px]">
        <p class="h900 mt-4">{{ $t('booking.moduleHeaderResources') }}</p>
        <div class="grid grid-cols-3 gap-10 mt-12">
          <CardBasic
            v-for="(resource, index) in bookingResourcesStore.all"
            :key="index"
            :title="resource.title"
            :image-url="resource.coverImage.source"
            @click="resourceSelected(resource)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
