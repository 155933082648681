<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import CoverView from '@views/shared/CoverView.vue';
import { useStore } from 'vuex';
import { get } from 'lodash';
import { format } from '@utils/date-fns';
import MediaIcons from '@components/shared/MediaIcons.vue';
import DescriptionBlock from '@components/shared/blocks/DescriptionBlock.vue';
import BasicBlock from '@components/shared/blocks/BasicBlock.vue';
import DepartmentsBlock from '@components/shared/blocks/DepartmentsBlock.vue';
import MediaPreview from '@views/overlays/MediaPreview.vue';
import MediaList from '@views/shared/MediaList.vue';
import PinFlow from '@utils/pinFlow';
import i18n from '@i18n';
import { useInstitutionStore } from '@stores/institution';

const store = useStore();
const institutionStore = useInstitutionStore();
const route = useRoute();

const mediaType = ref('');
const selectedMediaIndex = ref(-1);
const isLoading = ref(true);
const showMediaList = ref(false);
const hideMediaPreview = ref(false);

const profile = computed(() => store.getters['profiles/activeProfile']);
const myArchive = computed(() => store.getters['profiles/myArchive']);
const pinValidation = computed(() => store.getters['profiles/pinValidation']);

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const galleryPasswordProtected = computed(() => {
  return get(
    institutionStore.settings,
    'screen.galleryPasswordProtected',
    false
  );
});

const useExternalMedia = computed(() => {
  return get(institutionStore.settings, 'useExternalMedia', false);
});

const showResInfoDefault = computed(() => {
  return get(institutionStore.settings, 'showResInfoDefault', false);
});

const mediaIcons = computed(() => {
  if (useExternalMedia.value) {
    return [
      {
        name: 'externalMedia',
        fa: 'photo-video',
        amount: get(myArchive.value, 'galleries.length', 0),
      },
    ];
  }
  return [
    {
      name: 'pictures',
      fa: 'image',
      amount: get(myArchive.value, 'pictures.length', 0),
    },
    {
      name: 'galleries',
      fa: 'photo-video',
      amount: get(myArchive.value, 'galleries.length', 0),
    },
    {
      name: 'videos',
      fa: 'film',
      amount: get(myArchive.value, 'videos.length', 0),
    },
    {
      name: 'documents',
      fa: 'file-alt',
      amount: get(myArchive.value, 'documents.length', 0),
    },
  ];
});

const dreambrokerGallery = computed(() => {
  return get(myArchive.value, 'galleries', []);
});

const galleries = computed(() => {
  return get(myArchive.value, 'galleries', []);
});

const pictures = computed(() => {
  return get(myArchive.value, 'pictures', []);
});

const videos = computed(() => {
  return get(myArchive.value, 'videos', []);
});

const documents = computed(() => {
  return get(myArchive.value, 'documents', []);
});

const mediaItems = computed(() => {
  let items = [];
  switch (mediaType.value) {
    case 'externalMedia':
      if (dreambrokerGallery.value.length) items = dreambrokerGallery.value;
      break;
    case 'galleries':
      if (galleries.value.length) items = galleries.value;
      break;
    case 'galleryPictures':
      items =
        selectedMediaIndex.value >= 0 &&
        galleries.value[selectedMediaIndex.value].pictures;
      break;
    case 'pictures':
      if (pictures.value.length) items = pictures.value;
      break;
    case 'videos':
      if (videos.value.length) items = videos.value;
      break;
    case 'documents':
      if (documents.value.length) items = documents.value;
      break;
    default:
  }
  return items;
});

const showMediaPreview = computed(() => {
  return selectedMediaIndex.value > -1 && !hideMediaPreview.value;
});

const canRemoveMedia = computed(() => {
  mediaType.value !== 'galleryPictures';
});

const isEmployee = computed(() => {
  return profile.value.type === 'EMPLOYEE' && profile.value.title;
});

const homeDays = computed(() => {
  const daysTranslated = [];
  profile.value.homeDays.forEach((day) =>
    daysTranslated.push(i18n.global.t(`global.${day}`))
  );
  return `${i18n.global.t('resident.homeDays')}: ${daysTranslated.join(', ')}`;
});

watch(pinValidation, (pinData) => {
  if (!pinData.validPin || !pinData.pin) return;
  showMedia();
});

onMounted(async () => {
  await store.dispatch('profiles/setActiveProfile', profile.value);
  await store.dispatch('profiles/getMyArchive', {
    id: profile.value.id,
    type: profile.value.type,
    useCache: true,
  });

  isLoading.value = false;
});

onUnmounted(() => {
  store.dispatch('profiles/setActiveProfile', {});
});

function getBirthday(day) {
  return format(new Date(day), 'd MMMM, Y');
}

function closeMediaList() {
  mediaType.value = '';
  selectedMediaIndex.value = -1;
  showMediaList.value = false;
}

function showMedia(type) {
  if (type && type === 'galleryPicture') {
    mediaType.value = 'galleryPictures';
    showMediaList.value = true;
    hideMediaPreview.value = false;
    return;
  }

  if (
    mediaItems.value.length === 1 &&
    mediaType.value !== 'galleryPictures' &&
    mediaType.value !== 'galleries'
  ) {
    // if only one item, go straight to media-preview
    selectedMediaIndex.value = 0;
    hideMediaPreview.value = false;
    return;
  }

  showMediaList.value = true;
}

// Swap back selectedMediaType to 'galleries' if coming back from 'galleryPictures'
function closePreview(viewedMediaType) {
  if (viewedMediaType === 'galleryPictures') {
    mediaType.value = 'galleries';
    showMediaList.value = true;
  }

  selectedMediaIndex.value = -1;
}

function mediaIconSelected(selectedMediaType) {
  if (galleryPasswordProtected.value) {
    const flow = new PinFlow({
      entity: 'gallery',
      sharedDepartments: institutionStore.departments.value,
    });

    flow.grantAccessToGallery();
    mediaType.value = selectedMediaType;
  } else {
    mediaType.value = selectedMediaType;
    showMedia();
  }
}
</script>

<template>
  <div class="profile">
    <MediaPreview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      :allow-archive-remove="canRemoveMedia"
      @close-preview="closePreview($event)"
    />
    <CoverView
      v-if="!isLoading"
      :image-url="profile.displayImage"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="profileContent pt-20">
        <MediaIcons
          v-if="showResInfoDefault && documents"
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="documents.length"
          :color="moduleColor"
          @media-selected="mediaIconSelected"
        />

        <div v-if="!showMediaList">
          <div class="largeListItemHeader mb-20">
            {{ profile.displayName }}
          </div>

          <!-- Title -->
          <BasicBlock v-if="isEmployee" :text="profile.title" icon="tag" />

          <!-- Description -->
          <DescriptionBlock
            v-if="profile.description"
            :description="profile.description"
          />

          <!-- Birthday -->
          <BasicBlock
            v-if="profile.showBirthday && profile.birthday"
            :text="getBirthday(profile.birthday)"
            icon="birthday-cake"
          />

          <!-- Departments -->
          <DepartmentsBlock
            v-if="profile.departments.length"
            :departments="profile.departments"
          />

          <!-- Mobile -->
          <BasicBlock
            v-if="profile.mobile"
            :text="profile.mobile"
            icon="mobile"
          />

          <!-- Phone -->
          <BasicBlock v-if="profile.phone" :text="profile.phone" icon="phone" />

          <!-- Email -->
          <BasicBlock
            v-if="profile.showEmail && profile.email"
            :text="profile.email"
            icon="envelope"
          />

          <!-- Home days -->
          <BasicBlock
            v-if="profile.type === 'RESIDENT' && profile.homeDays.length"
            class="capitalize"
            :text="homeDays"
            icon="street-view"
          />
        </div>

        <MediaList
          v-else
          :items="mediaItems"
          :media-type="mediaType"
          class="pt-20"
          :can-close="true"
          @close="closeMediaList"
          @item-selected="
            {
              selectedMediaIndex = $event;
              showMedia();
            }
          "
          @gallery-picture-selected="
            {
              selectedMediaIndex = $event;
              showMedia('galleryPicture');
            }
          "
        />
      </div>
    </CoverView>
  </div>
</template>

<style lang="scss">
.profile {
  .profileContent {
    > *:not(.mediaIcons):not(.media-list) {
      padding: 0 100px;
    }
    .media-list {
      padding: 0;
      > * {
        padding: 0 100px;
      }
      > *.galleriesMargin {
        margin-bottom: 56px;
      }

      > *.galleries {
        padding: 0;
      }
    }
  }
}
</style>
