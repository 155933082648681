import { apolloClient } from '@utils/apollo';
import { journeyPlannerQuery } from '@graphql/queries/journeyPlanner.js';
import { useDepartmentStore } from '@stores/department';

const state = {
  all: [],
  isLoading: false,
  selectedFilter: {},
  lastUpdate: localStorage.getItem('lastUpdate'),
};

const mutations = {
  SET_DEFAULT_TRRAVEL_PLAN(state, journeyPlan) {
    state.all = journeyPlan;
  },
  SET_ISLOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_SELECTED_FILTER(state, filter) {
    state.selectedFilter = filter;
  },
  SET_LAST_UPDATE(_state, timestamp) {
    localStorage.setItem('lastUpdate', timestamp);
  },
  GET_LAST_UPDATE(state) {
    state.lastUpdate = localStorage.getItem('lastUpdate');
  },
};

const actions = {
  async getJourneyPlan({ commit }) {
    const departmentStore = useDepartmentStore();

    commit('SET_ISLOADING', true);

    if (!departmentStore.id) {
      throw new Error('No department id provided');
    }

    await apolloClient
      .query({
        query: journeyPlannerQuery,
        variables: {
          id: departmentStore.id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        commit('SET_LAST_UPDATE', new Date());

        commit(
          'SET_DEFAULT_TRRAVEL_PLAN',
          response.data.externalServices.getTravelPlan
        );

        commit('SET_ISLOADING', false);
      })
      .catch((error) => {
        console.log('Error getting travel plan', error);
        commit('SET_ISLOADING', false);
      });
  },
  setSelectedFilter({ commit }, filter) {
    commit('SET_SELECTED_FILTER', filter);
  },
  getLastUpdate({ commit }) {
    commit('GET_LAST_UPDATE');
  },
};

const getters = {
  all: (state) => state.all,
  isLoading: (state) => state.isLoading,
  selectedFilter: (state) => state.selectedFilter,
  lastUpdate: (state) => state.lastUpdate,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
