<script setup>
import { getModuleData } from '@utils/modules';
import { computed, onBeforeMount, ref } from 'vue';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';
import { useActivitiesStore } from '@stores/activities';
import { useCacheStore } from '@stores/cache';
import { useDepartmentStore } from '@stores/department';

const cache = useCacheStore();
const departmentStore = useDepartmentStore();
const activitiesStore = useActivitiesStore();

const isLoading = ref(true);

const moduleData = computed(() => {
  return departmentStore.hasValidExternalModule
    ? activitiesStore.external
    : activitiesStore.internal;
});

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().ACTIVITIES;

onBeforeMount(async () => {
  await cache.use(
    'activitiesStore.getActivities',
    activitiesStore.getActivities
  );
  isLoading.value = false;
});
</script>

<template>
  <div>
    <ModuleBaseView
      v-if="!isLoading"
      :moduleName="emptyKey"
      :moduleData="moduleData"
      :colorHex="colorHex"
      :icon="icon"
      :emptyKey="emptyKey"
      :routeName="routeName"
      :entryRouterName="entryRouterName"
    />
  </div>
</template>
