<script setup>
import { onBeforeMount } from 'vue';
import { getModuleData } from '@utils/modules';
import { useRouter } from 'vue-router';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';
import { default as ButtonEl } from '@components/shared/Button.vue';
import { useBookingResourcesStore } from '@stores/bookingResources';
import { useCacheStore } from '@stores/cache';

const bookingResourcesStore = useBookingResourcesStore();
const cache = useCacheStore();
const router = useRouter();

onBeforeMount(() => {
  cache.use(
    'bookingResourcesStore.getBookingResources',
    bookingResourcesStore.getBookingResources
  );
});

const bookingSelected = (booking) => {
  bookingResourcesStore.setSelectedBookingEntry(booking);
  router.push(`/bookings/booking/${booking.id}`);
};

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().BOOKING;
</script>

<template>
  <div>
    <ModuleBaseView
      :moduleName="emptyKey"
      :moduleData="bookingResourcesStore.resourceBookings || []"
      :colorHex="colorHex"
      :icon="icon"
      :emptyKey="emptyKey"
      :routeName="routeName"
      :entryRouterName="entryRouterName"
      @entryItemClicked="bookingSelected($event)"
    />

    <ButtonEl
      class="fixed bottom-[400px] right-8"
      backgroundColor="success"
      icon="plus"
      text="booking.newBooking"
      @click="router.push('bookings')"
    />
  </div>
</template>
