import { defineStore } from 'pinia';

// Logic for handling "cache" in the application to avoid fetching the same content in rapid succession
// If we already have the store function in the "cache" then we do nothing and let the components use the existing store value
// Otherwise we call the function passed to use()

export const useCacheStore = defineStore('cache', {
  state: () => ({
    lastDispatchTimes: {},
    pendingPromises: {}, // Store promises to prevent duplicate calls
  }),
  actions: {
    async use(name, action, params) {
      const currentTime = Date.now();

      if (
        this.lastDispatchTimes[name] &&
        currentTime - this.lastDispatchTimes[name] < 5000
      ) {
        return this.pendingPromises[name];
      }

      this.lastDispatchTimes[name] = currentTime;

      const promise = action(params);
      this.pendingPromises[name] = promise;

      try {
        await promise;
      } finally {
        delete this.pendingPromises[name];
      }
    },
  },
});
