<script setup>
import { computed } from 'vue';
import { useGeneralStore } from '@stores/general';

const generalStore = useGeneralStore();
const emit = defineEmits(['view-changed']);

const buttonStyle = {
  width: '63px',
  height: '63px',
};

const iconStyle = {
  width: '32px',
  height: '32px',
};

const buttonClasses = computed(() => {
  const baseClasses = [
    'rounded-full border-2',
    'transition-all duration-100 ease-out',
    'transform active:scale-90',
  ];
  const activeClasses =
    'opacity-100 border-neutral-300 bg-white transform shadow-lg';
  const inactiveClasses = 'opacity-50 border-transparent scale-90';

  return {
    grid: [
      ...baseClasses,
      ...(generalStore.activeViewType === 'grid'
        ? [activeClasses]
        : [inactiveClasses]),
    ],
    list: [
      ...baseClasses,
      ...(generalStore.activeViewType === 'list'
        ? [activeClasses]
        : [inactiveClasses]),
    ],
  };
});
</script>

<template>
  <div
    class="flex items-center justify-between p-2 rounded-full bg-neutral-100 border-2 border-neutral300"
    style="width: 175px; height: 76px"
  >
    <button
      @click="emit('view-changed', 'list')"
      :class="buttonClasses.list"
      :style="buttonStyle"
    >
      <fa-icon :icon="['far', 'list']" :style="iconStyle" />
    </button>

    <button
      @click="emit('view-changed', 'grid')"
      :class="buttonClasses.grid"
      :style="buttonStyle"
    >
      <fa-icon :icon="['far', 'table-cells-large']" :style="iconStyle" />
    </button>
  </div>
</template>
