import { isFuture } from 'date-fns';
import { formatTime } from '@utils/helpers.js';
import { useBookingResourcesStore } from '@stores/bookingResources';

export function getAllResourceBookings(resources) {
  if (!resources) return [];

  return resources
    .map((resource) => {
      return resource.bookings
        .filter((booking) => {
          return isFuture(new Date(booking.end));
        })
        .map((booking) => {
          return {
            ...booking,
            coverImage: resource.coverImage,
            title: booking.description,
            startDate: booking.start,
            endDate: booking.end,
            meta: {
              icon: 'clock',
              text: formatTime(new Date(booking.start)),
            },
          };
        });
    })
    .flat()
    .toSorted((a, b) => new Date(a.start) - new Date(b.start));
}
export function getBookingById(id) {
  const bookingResourcesStore = useBookingResourcesStore();

  return bookingResourcesStore.all
    .flatMap((resource) =>
      resource.bookings.map((booking) => ({
        ...booking,
        coverImage: resource.coverImage,
      }))
    )
    .find((booking) => booking.id === id);
}
