<script setup>
import { getModuleData } from '@utils/modules';
import { computed, onBeforeMount, ref } from 'vue';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';
import { useCacheStore } from '@stores/cache';
import { useActivitiesStore } from '@stores/activities';
import { useInstitutionStore } from '@stores/institution';

const institutionStore = useInstitutionStore();
const cache = useCacheStore();
const activitiesStore = useActivitiesStore();
const isLoading = ref(true);

const { colorHex, icon, emptyKey, routeName, entryRouterName, isExternal } =
  getModuleData().EXTERNAL_ACTIVITIES;

const externalConnectionIcon = computed(() => {
  return institutionStore.externalConnections?.[0]?.icon ?? icon;
});

onBeforeMount(async () => {
  await cache.use(
    'activitiesStore.getActivities',
    activitiesStore.getActivities
  );

  isLoading.value = false;
});
</script>

<template>
  <div>
    <ModuleBaseView
      v-if="!isLoading"
      :moduleName="emptyKey"
      :moduleData="activitiesStore.external"
      :colorHex="colorHex"
      :icon="externalConnectionIcon"
      :emptyKey="emptyKey"
      :routeName="routeName"
      :entryRouterName="entryRouterName"
      :isExternal="isExternal"
    />
  </div>
</template>
