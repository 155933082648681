import { defineStore } from 'pinia';
import { apolloClient } from '@utils/apollo';
import { get, cloneDeep } from 'lodash';
import { departmentQuery } from '@graphql/queries/department.js';
import { departmentWeatherQuery } from '@graphql/queries/departmentWeather.js';
import { myArchiveScreenQuery } from '@graphql/queries/myArchiveScreen.js';
import store from '@store';
import { getModuleData } from '@utils/modules';
import { useGeneralStore } from '@stores/general';
import * as Sentry from '@sentry/vue';

export const useDepartmentStore = defineStore('department', {
  state: () => ({
    loginKey: '',
    color: '',
    icon: '',
    id: null,
    hasValidExternalModule: null,
    institutionId: null,
    presentation: {
      description: '',
      image: '',
    },
    name: '',
    settings: {
      screenConfig: {
        gridSize: null,
        modules: [],
        footerConfig: [],
        timeout: null,
        slideshow: {
          duration: null,
        },
      },
      appConfig: {
        modules: [],
      },
    },
    weatherForecast: null,
    archive: {},
  }),
  actions: {
    checkIfValidExternalModule() {
      if (!store.getters['institution/didLoadExternalConnections']) {
        return;
      }

      const hasExternalActivitiesModule =
        this.settings?.screenConfig?.footerConfig?.includes(
          'EXTERNAL_ACTIVITIES'
        );

      const hasExternalConnections =
        store.getters['institution/externalConnections']?.length > 0;

      if (hasExternalActivitiesModule && !hasExternalConnections) {
        const errMsg = 'Found external footer module without valid connection';

        Sentry.captureException(new Error(errMsg), {
          tags: {
            config_error: errMsg,
          },
          extra: { departmentId: this?.id },
        });
      }

      this.hasValidExternalModule =
        hasExternalActivitiesModule && hasExternalConnections;
    },

    async getDepartment() {
      if (!this.id) {
        throw new Error('No department id provided');
      }

      await apolloClient
        .query({
          query: departmentQuery,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          const department = cloneDeep(get(response, 'data.department', []));
          const moduleData = getModuleData();

          const modules = department.settings.screenConfig.modules.map(
            (module) => {
              const rowValues = module.rowSpan.split('/');
              const colValues = module.colSpan.split('/');
              const name =
                moduleData[module.type].name ||
                moduleData[module.type].component;

              return {
                type: module.type,
                name,
                colorId: moduleData[module.type].colorId,
                colorHex: moduleData[module.type].colorHex,
                icon: moduleData[module.type].icon,
                row: {
                  start: rowValues[0],
                  end: rowValues[1],
                },
                col: {
                  start: colValues[0],
                  end: colValues[1],
                },
                frHeight: rowValues[1] - rowValues[0],
                frWidth: colValues[1] - colValues[0],
              };
            }
          );

          department.settings.screenConfig.modules = modules;
          this.$patch(department);

          this.checkIfValidExternalModule();
        })
        .catch((error) => {
          console.log('Error getting department', error);
        })
        .finally(() => {
          Sentry.setUser({
            id: this.id,
            departmentSettings: this.settings,
            institutionId: this.institutionId,
          });
        });
    },
    async setDepartmentId(id) {
      this.id = id;
    },
    async switchDepartment(department) {
      const generalStore = useGeneralStore();
      generalStore.setLoading(true);

      if (!department) {
        sessionStorage.removeItem('temp_login_key');
        sessionStorage.removeItem('temp_departments');
      } else {
        sessionStorage.setItem('temp_login_key', department.loginKey);
      }

      window.localStorage.clear();
      window.location.href = '/';
    },
    async getWeather() {
      await apolloClient
        .query({
          query: departmentWeatherQuery,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          this.weatherForecast = response;
        })
        .catch((error) => {
          console.error('Error fetching weather', error);
        });
    },
    async getArchive(id) {
      await apolloClient
        .query({
          query: myArchiveScreenQuery,
          variables: {
            profile: {
              profileType: 'DEPARTMENT',
              profileId: id,
            },
          },
        })
        .then((response) => {
          this.archive = response.data.myArchiveScreen;
        })
        .catch((error) => {
          console.log('Error getting my archive', error);
        });
    },
  },
});
