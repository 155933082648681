import { defineStore } from 'pinia';
import { apolloClient } from '@utils/apollo';
import constants from '@utils/constants';
import { get } from 'lodash';
import { activityRangeQuery } from '@graphql/queries/activityRange.js';
import { activityEntryQuery } from '@graphql/queries/activityEntry.js';
import { activitySignupScreenMutation } from '@graphql/mutations/activitySignupScreen.js';
import { activityCancelSignupScreenMutation } from '@graphql/mutations/activityCancelSignupScreen.js';
import { useInstitutionStore } from '@stores/institution';
import { useDepartmentStore } from '@stores/department';
import store from '@store';

export const useActivitiesStore = defineStore('activities', {
  state: () => ({
    all: [],
    internal: [],
    external: [],
    selected: {},
  }),
  actions: {
    async getActivities() {
      const departmentStore = useDepartmentStore();

      if (!departmentStore.id) {
        throw new Error('No department id provided');
      }

      const dateNow = new Date();
      const sixMonthsAheadDays = dateNow.setDate(dateNow.getDate() + 185);
      const dateEnd = new Date(sixMonthsAheadDays).toISOString().split('T')[0];

      await apolloClient
        .query({
          query: activityRangeQuery,
          variables: {
            filter: {
              departments: departmentStore.id,
            },
            dateFrom: new Date().toISOString().split('T')[0],
            dateTo: dateEnd,
            timezone: 'Europe/Copenhagen',
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          const institutionStore = useInstitutionStore();
          const allActivities = response.data.activityRange;

          this.allActivities = allActivities;
          const externalConnections = institutionStore.externalConnections;

          if (externalConnections.length) {
            const externalActivityInstitutionId = get(
              externalConnections,
              '[0.institution.id',
              -1
            );

            const externalActivities = allActivities.filter(
              (activity) =>
                activity.institution.id === externalActivityInstitutionId
            );

            this.external = externalActivities;

            const internalActivities = allActivities.filter(
              (activity) =>
                activity.institution.id !== externalActivityInstitutionId
            );

            this.internal = internalActivities;

            return;
          }

          this.internal = allActivities;
        })
        .catch((error) => {
          console.log('Error getting activities', error);
        });
    },
    async getSelectedActivity({ activityId }) {
      this.selected = {};

      await apolloClient
        .query({
          query: activityEntryQuery,
          variables: {
            id: activityId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          const activityEntry = response.data.activityEntry;

          const updatedResponse = {
            data: {
              activityEntry: {
                ...activityEntry,
                participants: activityEntry.participants.map((participant) => {
                  if (!participant.showImage)
                    participant.displayImage = constants.avatarPlaceholder;
                  return participant;
                }),
              },
            },
          };

          this.selected = updatedResponse.data.activityEntry;
        })
        .catch((error) => {
          console.log('Could not get activity entry: ', error);
        });
    },
    async activitySignupScreen({ subscription, profile, opt }) {
      const mutation =
        opt === 'in'
          ? activitySignupScreenMutation
          : activityCancelSignupScreenMutation;

      await apolloClient
        .mutate({
          mutation: mutation,
          variables: {
            subscription,
            profile,
          },
        })
        .then(async () => {
          await store.dispatch('profiles/setPinValidation', {
            validating: false,
            validPin: true,
          });

          this.getSelectedActivity({ activityId: subscription.id });
        })
        .catch((error) => {
          store.dispatch('profiles/setPinValidation', {
            validating: false,
            validPin: false,
          });
          throw new Error('Error signing up to activity', error);
        });
    },
  },
});
