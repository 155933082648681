<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { isBefore } from 'date-fns';
import { useRoute } from 'vue-router';
import { formatDistanceToNow } from '@utils/date-fns';
import { default as ButtonEl } from '@components/shared/Button.vue';
import { get } from 'lodash';
import CoverView from '@views/shared/CoverView.vue';
import MediaIcons from '@components/shared/MediaIcons.vue';
import TTS from '@components/shared/Tts.vue';
import MediaPreview from '@views/overlays/MediaPreview.vue';
import MediaList from '@views/shared/MediaList.vue';
import PinFlow from '@utils/pinFlow';
import i18n from '@i18n';
import DateTimeBlock from '@components/shared/blocks/DateTimeBlock.vue';
import ParticipantsBlock from '@components/shared/blocks/ParticipantsBlock.vue';
import DepartmentsBlock from '@components/shared/blocks/DepartmentsBlock.vue';
import DescriptionBlock from '@components/shared/blocks/DescriptionBlock.vue';
import PictogramsBlock from '@components/shared/blocks/PictogramsBlock.vue';
import TitleBlock from '@components/shared/blocks/TitleBlock.vue';
import DeadlineBlock from '@components/shared/blocks/DeadlineBlock.vue';
import PriceBlock from '@components/shared/blocks/PriceBlock.vue';
import ClubBlock from '@components/shared/blocks/ClubBlock.vue';
import InstitutionBlock from '@components/shared/blocks/InstitutionBlock.vue';
import SignupsBlock from '@components/shared/blocks/SignupsBlock.vue';
import { useActivitiesStore } from '@stores/activities';
import { useCacheStore } from '@stores/cache';
import { useInstitutionStore } from '@stores/institution';
import { useGeneralStore } from '@stores/general';

const cache = useCacheStore();
const store = useStore();
const institutionStore = useInstitutionStore();
const activitiesStore = useActivitiesStore();
const generalStore = useGeneralStore();
const route = useRoute();

const opt = ref('');
const mediaType = ref('');
const selectedMediaIndex = ref(-1);
const hideMediaPreview = ref(false);
const showMediaList = ref(false);

const activeProfile = computed(() => {
  return store.getters['profiles/activeProfile'];
});

const pinValidation = computed(() => {
  return store.getters['profiles/pinValidation'];
});

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const activityHideSignups = computed(() => {
  return get(institutionStore.settings, 'screen.activityHideSignups', false);
});

const profilesNotAlreadySignedUp = computed(() => {
  const departmentProfiles = store.getters['profiles/all'];
  return departmentProfiles.filter(
    (profile) =>
      !activitiesStore.selected?.value?.participants.some(
        (signedUpProfile) => signedUpProfile.id === profile.id
      )
  );
});

const imageUrl = computed(() => {
  return get(
    activitiesStore.selected,
    'coverImage.source',
    '/img/placeholder.png'
  );
});

const signupPossible = computed(() => {
  return (
    !maxSignupsReached.value &&
    activitiesStore.selected.signupPossible &&
    !activitiesStore.selected.cancelled
  );
});

const optoutPossible = computed(() => {
  return (
    !activitiesStore.selected.cancelled &&
    activitiesStore.selected.signupEnabled
  );
});

const signupExpired = computed(() => {
  return (
    !activitiesStore.selected.signupPossible &&
    !isBefore(new Date(), new Date(activitiesStore.selected.signupEnd))
  );
});

const maxSignupsReached = computed(() => {
  if (!activitiesStore.selected.maxSignups) return false;
  return (
    activitiesStore.selected.participants.length +
      activitiesStore.selected.responsible.length >=
    activitiesStore.selected.maxSignups
  );
});

const mediaIcons = computed(() => {
  return [
    {
      name: 'pictures',
      fa: 'image',
      amount: activitiesStore.selected.pictures.length,
    },
    {
      name: 'videos',
      fa: 'film',
      amount: activitiesStore.selected.videos.length,
    },
    {
      name: 'documents',
      fa: 'file-alt',
      amount: activitiesStore.selected.documents.length,
    },
  ];
});

const mediaItems = computed(() => {
  let items;

  switch (mediaType.value) {
    case 'pictures':
      items = activitiesStore.selected.pictures;
      break;
    case 'videos':
      items = activitiesStore.selected.videos;
      break;
    case 'documents':
      items = activitiesStore.selected.documents;
      break;
    default:
      items = [];
  }
  return items;
});

const showMediaPreview = computed(() => {
  return selectedMediaIndex.value > -1 && !hideMediaPreview.value;
});

const isAlreadyOptedInOut = computed(() => {
  const participantIds = activitiesStore.selected.participants.map(
    (participant) => participant.id
  );

  return participantIds.includes(activeProfile.value.id);
});

watch(pinValidation, (pinData) => {
  if (!pinData.validating) return;

  const signupData = {
    subscription: {
      id: activitiesStore.selected.id,
    },
    profile: {
      profileId: activeProfile.value.id,
      profileType: activeProfile.value.type,
      pin: pinData.pin.toString(),
    },
    opt: opt.value,
  };

  if (
    (opt.value === 'in' && !isAlreadyOptedInOut.value) ||
    (opt.value === 'out' && isAlreadyOptedInOut.value)
  ) {
    activitiesStore.activitySignupScreen(signupData);
  }
});

onMounted(() => {
  cache.use(
    'activitiesStore.getSelectedActivity',
    activitiesStore.getSelectedActivity,
    { activityId: route.params.id }
  );
});

function formatDeadline(activity) {
  if (activity.signupPossible) {
    return `${i18n.global.t('global.signUpDeadline')} ${formatDistanceToNow(new Date(activity.signupEnd), { addSufix: true })}`;
  }
  return i18n.global.t('global.signupNotNeeded');
}

function showProfiles(profileType) {
  generalStore.setActiveOverlay({
    name: 'participants-list',
    data: {
      title: `global.${profileType}`,
      profiles: activitiesStore.selected[profileType],
    },
  });
}

function optInOrOut(payload) {
  opt.value = payload;

  const flow = new PinFlow({
    entity: 'activity',
    text: 'activity.theActivity',
    opt: opt.value,
    title: activitiesStore.selected.title,
    participants:
      payload === 'in'
        ? profilesNotAlreadySignedUp.value
        : activitiesStore.selected.participants,
    sharedDepartments: activitiesStore.selected.departments,
  });
  flow.startSignupFlow('activity');
}

function closeMediaList() {
  mediaType.value = '';
  selectedMediaIndex.value = -1;
  showMediaList.value = false;
}

function mediaIconSelected(payload) {
  mediaType.value = payload;

  if (mediaItems.value.length === 1) {
    // if only one item, go straight to media-preview
    selectedMediaIndex.value = 0;
    hideMediaPreview.value = false;
    return;
  }

  showMediaList.value = true;
}
</script>

<template>
  <div class="activity">
    <MediaPreview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      :shared-departments="activity.departments"
      @close-preview="closeMediaList"
    />

    <CoverView
      v-if="activitiesStore.selected.id"
      :image-url="imageUrl"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="65"
    >
      <div class="activityContent tts-content pt-20">
        <MediaIcons
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="mediaItems.length"
          @media-selected="mediaIconSelected"
        />
        <TTS entity-type="ACTIVITY" />

        <div v-if="!showMediaList">
          <TitleBlock :text="activitiesStore.selected.title"></TitleBlock>

          <PictogramsBlock
            v-if="activitiesStore.selected.pictograms.length"
            :pictograms="activitiesStore.selected.pictograms"
          />

          <DescriptionBlock
            :description="activitiesStore.selected.description"
          />

          <DateTimeBlock
            :start="activitiesStore.selected.startDate"
            :end="activitiesStore.selected.endDate"
          />

          <DeadlineBlock
            v-if="activitiesStore.selected.signupPossible"
            :signup-expired="signupExpired"
            :text="formatDeadline(activitiesStore.selected)"
          />

          <PriceBlock
            v-if="!!activitiesStore.selected.price"
            :text="
              $n(activitiesStore.selected.price[0].price / 100, 'currency')
            "
          />

          <ParticipantsBlock
            v-if="activitiesStore.selected.participants.length"
            text="global.participantsPlural"
            :hide-participants="activityHideSignups"
            :module-color="moduleColor"
            :profiles="activitiesStore.selected.participants"
            @show-people="showProfiles('participants')"
          />

          <ParticipantsBlock
            v-if="activitiesStore.selected.responsible.length"
            text="global.responsiblePlural"
            :module-color="moduleColor"
            :profiles="activitiesStore.selected.responsible"
            @show-people="showProfiles('responsible')"
          />

          <DepartmentsBlock
            v-if="activitiesStore.selected.departments.length"
            :departments="activitiesStore.selected.departments"
          />

          <SignupsBlock
            v-if="maxSignupsReached"
            :max-signups-reached="maxSignupsReached"
            :activity="activitiesStore.selected"
          />

          <ClubBlock
            v-if="activitiesStore.selected.club"
            :image="activitiesStore.selected.club.logo.source"
            :text="activitiesStore.selected.club.name"
          />

          <InstitutionBlock />
        </div>

        <div v-else>
          <MediaList
            :items="mediaItems"
            :media-type="mediaType"
            :can-close="true"
            class="pt-20"
            @close="closeMediaList"
            @item-selected="selectedMediaIndex = $event"
          />
        </div>
      </div>

      <div
        v-if="!showMediaList"
        class="activityActions flex w-max left-full sticky"
      >
        <ButtonEl
          v-if="signupPossible"
          text="global.signUp"
          icon="check"
          background-color="success"
          text-color="white"
          class="mr-5 shadow-xsm"
          @click="optInOrOut('in')"
        />

        <ButtonEl
          v-if="optoutPossible"
          text="global.optOut"
          icon="minus-circle"
          background-color="error"
          text-color="white"
          class="shadow-xsm"
          @click="optInOrOut('out')"
        />
      </div>
    </CoverView>
  </div>
</template>

<style lang="scss">
.activity {
  .activityContent {
    &.participants {
      margin-top: 700px;
    }

    > *:not(.mediaIcons) {
      padding: 0 100px;
    }
  }

  .activityActions {
    bottom: 70px;
    padding-right: 80px;
  }
}
</style>
