<script setup>
import { ref, computed } from 'vue';
import { get, capitalize } from 'lodash';
import { differenceInDays, isSameDay, addDays, subDays } from 'date-fns';
import { format } from '@utils/date-fns';
import i18n from '@i18n';
import { useInstitutionStore } from '@stores/institution';
import { useDepartmentStore } from '@stores/department';

const institutionStore = useInstitutionStore();
const departmentStore = useDepartmentStore();
const emit = defineEmits(['handle-selected-day']);

const selectedDay = ref(new Date());
const inactiveClasses =
  'bg-schedule-light border border-schedule-dark-default opacity-50';
const activeClasses = 'bg-schedule-light border border-schedule-dark-default';

const formattedSelectedDate = computed(() => {
  if (isSameDay(selectedDay.value, today.value))
    return i18n.global.t('global.today');
  return capitalize(format(selectedDay.value, 'EEEE do LLLL'));
});

const shownDays = computed(() => {
  // If the department has checked overwrite institution setting it will be in the shift array.
  const matchingDepartment = institutionStore.settings.shift?.find(
    ({ departmentId }) => departmentId == departmentStore.id
  );

  return (
    get(
      matchingDepartment,
      'daysForward',
      departmentStore.settings.shift.daysForward
    ) - 1
  ); // Subtract 1 because the core admin setting is not 0 based but 1 should mean 1 days forward.
});

const today = computed(() => {
  return new Date();
});

const canGoBack = computed(() => {
  const differenceFromToday = differenceInDays(today.value, selectedDay.value);
  return differenceFromToday < shownDays.value;
});

const canGoForward = computed(() => {
  const differenceFromToday = differenceInDays(selectedDay.value, today.value);
  return differenceFromToday < shownDays.value;
});

function goBack() {
  if (!canGoBack.value) return;
  selectedDay.value = subDays(selectedDay.value, 1);
  emit('handle-selected-day', selectedDay.value);
}

function goForward() {
  if (!canGoForward.value) return;
  selectedDay.value = addDays(selectedDay.value, 1);
  emit('handle-selected-day', selectedDay.value);
}
</script>

<template>
  <div
    class="bottom-[310px] bg-white h-48 flex p-12 flex-row w-full overflow-none z-20"
  >
    <div
      class="w-24 h-24 rounded-lg flex items-center justify-center"
      :class="canGoBack ? activeClasses : inactiveClasses"
      @click="goBack()"
    >
      <fa-icon class="text-charcoal text-5xl" :icon="['fal', 'angle-left']" />
    </div>

    <div
      class="h-24 flex-1 mx-12 rounded-lg flex items-center justify-center"
      :class="inactiveClasses"
    >
      <p class="text-3xl font-bold text-black">
        {{ formattedSelectedDate }}
      </p>
    </div>

    <div
      class="w-24 h-24 rounded-lg flex items-center justify-center"
      :class="canGoForward ? activeClasses : inactiveClasses"
      @click="goForward()"
    >
      <fa-icon class="text-charcoal text-5xl" :icon="['fal', 'angle-right']" />
    </div>
  </div>
</template>
