<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { isEmpty, get } from 'lodash';
import CoverView from '@views/shared/CoverView.vue';
import MediaIcons from '@components/shared/MediaIcons.vue';
import MediaPreview from '@views/overlays/MediaPreview.vue';
import MediaList from '@views/shared/MediaList.vue';
import { useInstitutionStore } from '@stores/institution';

const store = useStore();
const institutionStore = useInstitutionStore();
const route = useRoute();

const mediaType = ref('');
const selectedMediaIndex = ref(-1);

const galleryEntry = computed(() => {
  return store.getters['galleries/galleryEntry'];
});

const activeProfile = computed(() => {
  return store.getters['profiles/activeProfile'];
});

const useExternalMedia = computed(() => {
  return get(institutionStore.settings, 'useExternalMedia', false);
});

const mediaIcons = computed(() => {
  if (useExternalMedia.value) {
    // TODO: Use when implementing the Dreambroker solution
    return [
      {
        name: 'media',
        fa: 'photo-video',
        amount: galleryEntry.value.media.length,
      },
    ];
  }
  return [
    {
      name: 'pictures',
      fa: 'image',
      amount: galleryEntry.value.pictures.length,
    },
    {
      name: 'videos',
      fa: 'film',
      amount: galleryEntry.value.videos.length,
    },
  ];
});

const mediaItems = computed(() => {
  if (useExternalMedia.value) {
    return galleryEntry.value.media;
  }

  switch (mediaType.value) {
    case 'videos':
      return galleryEntry.value.videos;
    case 'pictures':
    default: // Default to pictures if nothing is specified.
      return galleryEntry.value.pictures;
  }
});

const selectedMediaItem = computed(() => {
  if (isEmpty(galleryEntry.value)) {
    return;
  }

  if (useExternalMedia.value) {
    return galleryEntry.value.media[selectedMediaIndex.value];
  }

  return mediaItems.value?.[selectedMediaIndex.value] || [];
});

const hasSelectedMediaItem = computed(() => {
  return !isEmpty(selectedMediaItem.value);
});

watch(activeProfile, (newValue) => {
  if (!isEmpty(newValue)) {
    store.dispatch('profiles/getMyArchive', {
      id: activeProfile.value.id,
      type: activeProfile.value.type,
    });
  }
});

onMounted(() => {
  const id = route?.params?.id || null;
  if (!id) return;
  store.dispatch('galleries/getGalleryEntry', id);
  mediaType.value = useExternalMedia.value ? 'media' : 'pictures';
});

onUnmounted(() => {
  store.dispatch('galleries/resetGalleryEntry');
});
</script>

<template>
  <div class="galleryEntry">
    <MediaPreview
      v-if="hasSelectedMediaItem"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      :allow-archive-remove="false"
      @close-preview="selectedMediaIndex = -1"
    />

    <CoverView
      v-if="galleryEntry.id"
      :image-url="galleryEntry.coverImage.source"
      :gradient="false"
      :margin-top="60"
    >
      <div class="galleryContent">
        <MediaIcons
          :icons="mediaIcons"
          :media-type="mediaType"
          @media-selected="mediaType = $event"
        />

        <div class="largeListItemHeader">
          {{ galleryEntry.title }}
        </div>

        <MediaList
          class="pt-10"
          :items="mediaItems"
          :media-type="mediaType"
          @item-selected="selectedMediaIndex = $event"
        />
      </div>
    </CoverView>
  </div>
</template>

<style lang="scss">
.galleryContent {
  padding: 80px 100px 0px;
}
.video {
  height: 495px;
}
</style>
