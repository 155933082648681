import { apolloClient } from '@utils/apollo';
import { newsQuery } from '@graphql/queries/news.js';
import { useDepartmentStore } from '@stores/department';

const state = {
  all: [],
};

const mutations = {
  SET_DEFAULT_NEWS(state, news) {
    state.all = news;
  },
};

const actions = {
  async getNews({ commit }) {
    const departmentStore = useDepartmentStore();

    if (!departmentStore.id) {
      throw new Error('No department id provided');
    }

    await apolloClient
      .query({
        query: newsQuery,
        variables: {
          filter: {
            departments: departmentStore.id,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        commit('SET_DEFAULT_NEWS', response.data.news);
      })
      .catch((error) => {
        console.log('Error getting news', error);
      });
  },
};

const getters = {
  all: (state) => state.all,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
