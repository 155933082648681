import { defineStore } from 'pinia';
import { apolloClient } from '@utils/apollo';
import { institutionQuery } from '@graphql/queries/institution.js';
import { appConnectionsScreenQuery } from '@graphql/queries/appConnectionsScreen.js';
import { useDepartmentStore } from '@stores/department';

export const useInstitutionStore = defineStore('institution', {
  state: () => ({
    id: null,
    name: null,
    locale: null,
    logo: {},
    departments: [], // All departments under the institution that should be visible to each other, e.g in pinFlows.
    availableDepartments: [], // Departments that are able to be switched to, via the departmentSwitcher.
    externalConnections: [],
    settings: {},
    didLoadExternalConnections: false,
  }),
  actions: {
    async getInstitution() {
      await apolloClient
        .query({
          query: institutionQuery,
        })
        .then((response) => {
          const departmentStore = useDepartmentStore();

          // TODO when BE passes loginKey for requesting department https://proreact.atlassian.net/browse/IBG-6214,
          // Use this task to change the filtering logic: https://proreact.atlassian.net/browse/IBG-6215.
          // But for now exclude on empty string '', as the current department loginKey is null while we still want to include it.
          const availableDepartments = response.data.institution.departments
            .filter(
              ({ settings, loginKey, employeeOnly }) =>
                settings.screenConfig.departmentSwitcher &&
                !employeeOnly &&
                loginKey !== ''
            )
            .sort(
              (a, b) =>
                (b.id === departmentStore.id) - (a.id === departmentStore.id)
            );

          this.$patch({
            ...response.data.institution,
            availableDepartments,
          });
        })
        .catch((error) => {
          throw new Error('Error getting institution', error);
        });
    },
    async getExternalConnections() {
      await apolloClient
        .query({
          query: appConnectionsScreenQuery,
        })
        .then((response) => {
          this.externalConnections = response.data.appConnectionsScreen;
        })
        .catch((error) => {
          console.error('Error getting external connections', error);
        })
        .finally(() => {
          this.didLoadExternalConnections = true;

          const departmentStore = useDepartmentStore();
          departmentStore.checkIfValidExternalModule();
        });
    },
  },
});
