<script setup>
import { computed, onMounted, onBeforeMount, onUpdated } from 'vue';
import { useRouter } from 'vue-router';
import { getStartDay } from '@utils/helpers.js';
import ModuleSectionList from '@components/moduleContentList/ModuleSectionList.vue';
import ModuleContentGrid from '@components/moduleContentGrid/ModuleContentGrid.vue';
import ModuleIconTitle from '@components/ModuleIconTitle.vue';
import ModuleEmptyCard from '@components/cards/moduleEmptyCard/ModuleEmptyCard.vue';
import { capitalize } from 'lodash';
import i18n from '@i18n';
import { useGeneralStore } from '@stores/general';

const props = defineProps({
  shouldShowSectionDivider: {
    type: Boolean,
    required: false,
    default: true,
  },
  moduleData: {
    required: true,
    type: Array,
  },
  filter: {
    required: false,
    type: String,
  },
  groupByKey: {
    required: false,
    type: String,
    default: 'startDate',
  },
  shouldShowDate: {
    type: Boolean,
    default: true,
  },
  shouldShowInfoTag: {
    type: Boolean,
    default: true,
  },
  shouldShowMediaCount: {
    type: Boolean,
    default: false,
  },
  colorHex: {
    required: true,
    type: String,
  },
  icon: {
    required: true,
    type: String,
  },
  emptyKey: {
    required: true,
    type: String,
  },
  routeName: {
    required: true,
    type: String,
  },
  entryRouterName: {
    type: String,
    required: true,
  },
  moduleName: {
    required: true,
    type: String,
  },
  isExternal: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const generalStore = useGeneralStore();
const router = useRouter();
const emit = defineEmits(['entryItemClicked']);

const entryItemClicked = (item) => {
  emit('entryItemClicked', item);

  // If booking, skip routing to let BookingsModule handle that.
  if (props.routeName.includes('booking')) return;
  router.push({
    name: props.entryRouterName,
    params: { id: item.id },
  });
};

onBeforeMount(() => {
  if (props?.moduleData) {
    generalStore.setActiveViewType(
      props.moduleData?.length > 3 ? 'grid' : 'list'
    );
  }
});

onMounted(() => {
  const content = document.getElementById('content');
  if (content) {
    content.scrollIntoView(true, { behavior: 'instant', block: 'start' });
  }
});

onUpdated(() => {
  const content = document.getElementById('content');
  if (content) {
    content.scrollIntoView(true, { behavior: 'instant', block: 'start' });
  }
});

const groupedData = computed(() => {
  if (!props.moduleData || !props.groupByKey) return;

  let groupName;
  const { moduleData, groupByKey } = props;
  // Group data by specified key e.g startDate for meals etc and category for galleries.
  return moduleData.reduce((groupedData, entry) => {
    // Determine group name based on groupByKey
    if (groupByKey === 'startDate') {
      groupName = capitalize(getStartDay(entry.startDate, false, false));
    } else if (groupByKey === 'category') {
      groupName =
        entry.category?.title || i18n.global.t('global.uncategorized');
    } else {
      groupName = entry[groupByKey];
    }
    // Initialize group if it doesn't exist
    if (!groupedData[groupName]) {
      groupedData[groupName] = { sectionTitle: groupName, sectionEntries: [] };
    }
    // Add entry to the appropriate group
    groupedData[groupName].sectionEntries.push(entry);
    return groupedData;
  }, {});
});

// Return all data if no filter is applied or data based on filter.
const filteredGroupedData = computed(() => {
  // If theres no filter, or its 'AllCategoriesSelected', return all the data.
  if (!props.filter || props.filter === 'AllCategoriesSelected')
    return groupedData.value;
  return { [props.filter]: groupedData.value[props.filter] || {} };
});
</script>

<template>
  <ModuleEmptyCard
    v-if="!moduleData.length >= 1"
    class="flex h-full"
    :emptyKey="emptyKey"
    :backgroundColor="colorHex"
    iconType="fas"
    :icon="icon"
    :isExternal="isExternal"
  />
  <div v-else>
    <div class="h-[920px] bg-white" id="top-spacing" />
    <div
      id="content"
      class="flex flex-col z-10 contain-content min-h-fit bg-white items-center pb-10"
    >
      <ModuleIconTitle
        :moduleName="moduleName"
        :backgroundColor="colorHex"
        :icon="icon"
        class="pt-2"
        :isExternal="isExternal"
      />

      <ModuleContentGrid
        class="z-10 min-h-[1320px]"
        v-if="generalStore.activeViewType === 'grid'"
        :content="moduleData"
        :backgroundColor="colorHex"
        :routeName="routeName"
        :entryRouterName="entryRouterName"
        :shouldShowMediaCount="shouldShowMediaCount"
        :shouldShowInfoTag="shouldShowInfoTag"
        :shouldShowDate="shouldShowDate"
        @entryItemClicked="entryItemClicked($event)"
      />

      <ModuleSectionList
        v-if="generalStore.activeViewType === 'list'"
        :shouldShowSectionDivider="shouldShowSectionDivider"
        :sections="filteredGroupedData"
        :filter="filter"
        :backgroundColor="colorHex"
        class="min-h-[1320px]"
        :routeName="routeName"
        :entryRouterName="entryRouterName"
        :shouldShowMediaCount="shouldShowMediaCount"
        :shouldShowInfoTag="shouldShowInfoTag"
        :shouldShowDate="shouldShowDate"
        @entryItemClicked="entryItemClicked($event)"
      />
    </div>
  </div>
</template>
