<script setup>
import { onBeforeMount } from 'vue';
import { useGeneralStore } from '@stores/general';

const generalStore = useGeneralStore();

onBeforeMount(async () => {
  // Store sets dimmer to -1 if there is no localStorage value.
  if (generalStore.dimmer === -1) {
    // Check application storage value
    const storageValue = localStorage.getItem('dimmer');
    // If the value is less than 0, set to 100 to prevent blacking out the screen.
    if (storageValue < 1) {
      generalStore.setDimmer(100);
    } else {
      generalStore.setDimmer(storageValue);
    }
  }
});
</script>

<template>
  <div
    class="transition-opacity duration-500 screenDimmer absolute inset-0 z-100 bg-gray-900 pointer-events-none"
    :style="{ opacity: (100 - generalStore.dimmer) / 105 }"
  />
</template>
