import { createStore } from 'vuex';

import profiles from '@store/modules/profiles';
import galleries from '@store/modules/galleries';
import news from '@store/modules/news';
import meals from '@store/modules/meals';
import workSchedule from '@store/modules/workSchedule';
import meetings from '@store/modules/meetings';
import ourHome from '@store/modules/ourHome';
import journeyPlanner from '@store/modules/journeyPlanner';
import idleHandler from '@store/modules/idleHandler';

// Plugin to intercept actions and return the current state if the action was last dispatched less than 5 seconds ago
const cachePlugin = (store) => {
  const lastDispatchTimes = {};
  const lastPayload = {};
  const actionResultsCache = {};

  // Override dispatch
  const originalDispatch = store.dispatch;

  store.dispatch = async function (actionName, payload) {
    const currentTime = Date.now();

    if (
      payload?.useCache &&
      JSON.stringify(lastPayload[actionName]) === JSON.stringify(payload) &&
      lastDispatchTimes[actionName] &&
      currentTime - lastDispatchTimes[actionName] < 5000
    ) {
      // Return cached result if action was dispatched less than 5 seconds ago
      return actionResultsCache[actionName];
    }
    // If more than 5 seconds have passed, proceed with normal dispatch
    const result = await originalDispatch.call(store, actionName, payload);

    // Cache the result and update the last dispatch time
    lastDispatchTimes[actionName] = currentTime;
    lastPayload[actionName] = payload;
    actionResultsCache[actionName] = result;

    return result;
  };
};

export default createStore({
  modules: {
    profiles,
    galleries,
    news,
    meals,
    workSchedule,
    meetings,
    ourHome,
    journeyPlanner,
    idleHandler,
  },
  plugins: [cachePlugin],
});
